<template>
  <v-container fluid pa-0 ma-0 :background="$vuetify.breakpoint.mdAndUp" :background-mobile="$vuetify.breakpoint.smAndDown && step === 1">
    <v-layout wrap max-width-1400 full-width ma-auto px-3 pt-5 v-if="step === 1">
      <v-flex xs12 md5 lg6 v-if="$vuetify.breakpoint.mdAndUp">
        <v-layout wrap fill-height align-center>
          <v-flex xs12 align-self-end>
            <p  class="graphik-light secondary--text max-width-500" :class="{'font-36': $vuetify.breakpoint.lgAndUp, 'font-30': $vuetify.breakpoint.mdOnly}">
              {{ $ml.get('landing_car_title_1') }}
              <span class="graphik-bold"> {{ $ml.get('landing_car_title_2') }}</span>
              <span v-if="company"> {{ $ml.get('landing_company_' + company) }}</span>
            </p>
            
            <p class="graphik-light secondary--text" :class="{'font-25': $vuetify.breakpoint.lgAndUp, 'font-20': $vuetify.breakpoint.mdOnly}">{{ $ml.get('landing_car_description' + (company ? '_' + company : '')) }}</p>
          </v-flex>

          <v-flex xs12>
            <v-img
              position="left bottom"
              contain
              :src="images.car"
              :lazy-src="images.car"
            ></v-img>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 md7 lg6 d-flex :pa-3="$vuetify.breakpoint.mdAndUp" :py-3="$vuetify.breakpoint.smAndDown">
        <v-layout>
          <v-flex shrink>
            <v-img
              position="left bottom"
              contain
              :width="$vuetify.breakpoint.smAndUp ? '80px' : '60px'"
              :height="$vuetify.breakpoint.smAndUp ? '80px' : '60px'"
              :src="images.cuco_avatar"
              :lazy-src="images.cuco_avatar"
            ></v-img>
          </v-flex>

          <v-flex shrink arrow-right>
            <v-img
              position="right"
              contain
              width="25px"
              height="45px"
              :src="images.cuco_dialog_triangle"
              :lazy-src="images.cuco_dialog_triangle"
            ></v-img>
          </v-flex>

          <v-flex grow>
            <v-card class="elevation" :max-width="$vuetify.breakpoint.mdAndUp ? '400px' : 'auto'" :class="{'dialog-bubble': $vuetify.breakpoint.xsOnly}">
              <v-card-text>
                <p :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-14': $vuetify.breakpoint.xsOnly}" class="graphik-bold secondary--text">{{ $ml.get('general_hello_cuco') }}</p>

                <p :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-13': $vuetify.breakpoint.xsOnly}" class="graphik-light">{{ $ml.get('general_cuco_will_help') }}</p>

                <p class="font-19 graphik-medium secondary--text" v-if="$vuetify.breakpoint.mdAndUp">{{ $ml.get('quoter_car_description') }}</p>
                
                <v-form v-model="valid_quote_step_1" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="quote.car_model"
                        :items="options.car_models"
                        :label="$ml.get('fields_car_model_quoter')"
                        solo
                        clearable
                        :disabled="g_isLoading"
                        required
                        item-value="model"
                        item-text="model"
                        :rules="g_required_rules"
                        :no-data-text="$ml.get('general_no_results')"
                        class="hidden-shadow quoter-input"
                        ref="car_model"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-autocomplete
                        v-model="quote.car_brand"
                        :items="options.car_brands"
                        :label="$ml.get('fields_car_brand_quoter')"
                        item-text="brand"
                        item-value="brand"
                        solo
                        :disabled="!quote.car_model || g_isLoading"
                        clearable
                        required
                        :rules="g_required_rules"
                        :no-data-text="$ml.get('general_no_results')"
                        class="hidden-shadow quoter-input"
                        ref="car_brand"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <v-autocomplete
                        v-model="quote.car_uuid"
                        :items="options.car_uuids"
                        :label="$ml.get('fields_car_uuid_quoter')"
                        item-text="version"
                        item-value="uuid"
                        solo
                        :disabled="!quote.car_model || !quote.car_brand || g_isLoading"
                        clearable
                        required
                        :rules="g_required_rules"
                        :no-data-text="$ml.get('general_no_results')"
                        class="hidden-shadow quoter-input"
                        ref="car_uuid"
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>

              <v-card-actions class="px-3 pt-0 pb-3" v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn 
                  color="pantene"
                  depressed large
                  :disabled="!valid_quote_step_1"
                  :loading="g_isLoading"
                  class="normalcase white--text graphik-bold-italic ma-0 border-radius-5"
                  @click="step = 2"
                >{{ $ml.get('general_continue') }} <v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>

                <v-spacer></v-spacer>

                <p class="font-13 graphik-light mb-0">{{ $ml.get('general_step_1_of_2') }}</p>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown">
        <p class="font-19 graphik-medium secondary--text">{{ $ml.get('quoter_car_description') }}</p>
        
        <v-form v-model="valid_quote_step_1">
          <v-layout row wrap>
            <v-flex xs12>
              <v-autocomplete
                v-model="quote.car_model"
                :items="options.car_models"
                :label="$ml.get('fields_car_model_quoter')"
                solo
                clearable
                :disabled="g_isLoading"
                required
                item-value="model"
                item-text="model"
                :rules="g_required_rules"
                :no-data-text="$ml.get('general_no_results')"
                class="hidden-shadow quoter-input"
                ref="car_model"
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12>
              <v-autocomplete
                v-model="quote.car_brand"
                :items="options.car_brands"
                :label="$ml.get('fields_car_brand_quoter')"
                item-text="brand"
                item-value="brand"
                solo
                :disabled="!quote.car_model || g_isLoading"
                clearable
                required
                :rules="g_required_rules"
                :no-data-text="$ml.get('general_no_results')"
                class="hidden-shadow quoter-input"
                ref="car_brand"
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12>
              <v-autocomplete
                v-model="quote.car_uuid"
                :items="options.car_uuids"
                :label="$ml.get('fields_car_uuid_quoter')"
                item-text="version"
                item-value="uuid"
                solo
                :disabled="!quote.car_model || !quote.car_brand || g_isLoading"
                clearable
                required
                :rules="g_required_rules"
                :no-data-text="$ml.get('general_no_results')"
                class="hidden-shadow quoter-input"
                ref="car_uuid"
              ></v-autocomplete>
            </v-flex>
          </v-layout>
        </v-form>

        <v-layout wrap>
          <v-flex xs12 text-xs-center>
            <v-btn 
              color="pantene"
              depressed large block
              :disabled="!valid_quote_step_1"
              :loading="g_isLoading"
              class="normalcase white--text graphik-bold-italic ma-0 border-radius-5"
              @click="step = 2"
            >{{ $ml.get('general_continue') }} <v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>

            <p class="font-13 graphik-light mt-3">{{ $ml.get('general_step_1_of_2') }}</p>
          </v-flex>
        </v-layout>

        <v-img
          position="left bottom"
          contain
          :src="images.car"
          :lazy-src="images.car"
        ></v-img>
      </v-flex>

      <v-flex xs12 text-xs-center>
        <p class="white--text font-20 graphik-medium mb-2">{{ $ml.get('general_read_more') }}</p>
        <v-icon color="white" class="mb-3">fas fa-chevron-circle-down</v-icon>
      </v-flex>
    </v-layout>

    <v-layout wrap max-width-1400 full-width ma-auto px-3 pt-5 v-if="step === 2">
      <v-flex xs12 text-xs-center>
        <v-img
          position="center center"
          contain
          class="ma-auto"
          width="80px"
          height="80px"
          :src="images.cuco_avatar"
          :lazy-src="images.cuco_avatar"
        ></v-img>

        <v-img
          v-if="$vuetify.breakpoint.mdAndUp"
          position="center center"
          contain
          class="ma-auto rotate-90"
          width="25px"
          height="45px"
          :src="images.cuco_dialog_triangle"
          :lazy-src="images.cuco_dialog_triangle"
        ></v-img>
      </v-flex>

      <v-flex xs12 pb-5 max-width-900 ma-auto>
        <v-card :class="{'elevation': $vuetify.breakpoint.mdAndUp}" :flat="$vuetify.breakpoint.smAndDown" :color="$vuetify.breakpoint.smAndDown ? 'transparent' : 'white'">
          <v-card-text :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
            <!-- <p class="graphik-light secondary--text text-xs-center mb-2" :class="{'font-38': $vuetify.breakpoint.mdAndUp, 'font-18': $vuetify.breakpoint.smAndDown}">{{ $ml.get('quoter_meet') }}</p> -->
            <p class="graphik-light secondary--text text-xs-center mt-2 mb-4" :class="{'font-22': $vuetify.breakpoint.mdAndUp, 'font-16': $vuetify.breakpoint.smAndDown}">{{ $ml.get('quoter_receive_quote') }} <span class="graphik-bold lowercase">{{ $ml.get('general_quote_noun') }}</span></p>

            <v-form v-model="valid_quote_step_2">
              <v-layout row wrap>
                <v-flex xs12 sm4 px-1 :pr-2="$vuetify.breakpoint.smAndUp">
                  <v-text-field
                    v-model="quote.name"
                    solo
                    :label="$ml.get('fields_name')"
                    clearable
                    required
                    :disabled="g_isLoading"
                    :rules="g_required_rules"
                    class="hidden-shadow quoter-input"
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 px-1 :px-2="$vuetify.breakpoint.smAndUp">
                  <v-autocomplete
                    v-model="quote.gender"
                    :label="$ml.get('fields_gender')"
                    :items="options.genders"
                    solo
                    clearable
                    :disabled="g_isLoading"
                    required
                    :rules="g_required_rules"
                    :no-data-text="$ml.get('general_no_results')"
                    class="hidden-shadow quoter-input"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs6 sm4 px-1 :pl-2="$vuetify.breakpoint.smAndUp">
                  <v-text-field
                    v-model="age"
                    solo
                    clearable
                    required
                    mask="##"
                    :disabled="g_isLoading"
                    :rules="g_required_rules"
                    class="hidden-shadow quoter-input"
                    :label="$ml.get('fields_age')"
                  ></v-text-field>
                </v-flex>


                <v-flex xs12 sm4 px-1 :pr-2="$vuetify.breakpoint.smAndUp">
                  <v-text-field
                    v-model="quote.neighborhood_zip_code"
                    :label="$ml.get('fields_zip_code')"
                    solo
                    clearable
                    required
                    :disabled="g_isLoading"
                    mask="#####"
                    :rules="g_zip_code_rules"
                    class="hidden-shadow quoter-input"
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 px-1 :px-2="$vuetify.breakpoint.smAndUp">
                  <v-text-field
                    v-model="quote.email"
                    solo
                    :label="$ml.get('fields_email')"
                    clearable
                    required
                    :disabled="g_isLoading"
                    :rules="g_email_rules"
                    class="hidden-shadow quoter-input"
                    :hint="$ml.get('hint_email')"
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 px-1 :pl-2="$vuetify.breakpoint.smAndUp">
                  <v-text-field
                    v-model="quote.cellphone"
                    solo
                    :label="$ml.get('fields_cellphone')"
                    clearable
                    required
                    :disabled="g_isLoading"
                    mask="phone"
                    :rules="g_phone_rules"
                    class="hidden-shadow quoter-input"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>

            <v-layout row wrap>
              <v-flex xs12 pt-3 px-1>
                <v-checkbox v-model="accept_terms" color="secondary" :label="$ml.get('general_accept_terms')" hide-actions class="ma-0 pa-0 shrink mr-3" :class="{'font-12': $vuetify.breakpoint.smAndDown}"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-actions class="px-1 pt-0 pb-3" :class="{'d-block text-xs-center': $vuetify.breakpoint.smAndDown, 'px-3': $vuetify.breakpoint.mdAndUp}">
            <v-btn 
              color="pantene"
              depressed large :block="$vuetify.breakpoint.smAndDown"
              :disabled="!valid_quote_step_2 || !accept_terms"
              :loading="g_isLoading"
              class="normalcase white--text graphik-bold-italic ma-0 border-radius-5"
              @click="createQuote"
            >{{ $ml.get('general_quote') }} <v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>

            <p class="font-12 graphik-light dark-grey--text px-3 mb-0" :class="{'py-3': $vuetify.breakpoint.smAndDown}"><v-icon small class="mr-1" color="primary">fas fa-lock</v-icon> {{ $ml.get('general_protected_data') }}</p>

            <v-spacer></v-spacer>

            <p class="font-13 graphik-light mb-0" :class="{'py-3': $vuetify.breakpoint.smAndDown}">{{ $ml.get('general_step_2_of_2') }}</p>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'QuoterCar',
  props: {
    company: {
      type: String
    }
  },
  data () {
    return {
      step: 1,
      accept_terms: false,
      valid_quote_step_1: false,
      valid_quote_step_2: false,
      birthdate_menu: false,
      age: '',
      quote: {
        name: this.$route.query.name || '',
        email: this.$route.query.email || '',
        cellphone: this.$route.query.cellphone || '',
        birthday: this.$route.query.birthday || null,
        gender: this.$route.query.gender || null,
        car_model: parseInt(this.$route.query.car_model) || '',
        car_brand: this.$route.query.car_brand || '',
        car_uuid: this.$route.query.car_version_uuid || '',
        neighborhood_zip_code: this.$route.query.zip_code || ''
      },
      options: {
        genders: [
          {
            text: this.$ml.get('fields_options_gender_male'),
            value: 'H'
          },
          {
            text: this.$ml.get('fields_options_gender_female'),
            value: 'M'
          }
        ],
        car_models: [],
        car_brands: [],
        car_uuids: []
      },
      images: {
        car: require('@/assets/img/images/banner_car_landing' + (this.company ? ('_' + this.company) : '') + '.png'),
        cuco_avatar: require('@/assets/img/images/cuco_avatar.png'),
        cuco_dialog_triangle: require('@/assets/img/images/cuco_dialog_triangle.png')
      }
    }
  },
  watch: {
    age (v) {
      if (v) {
        this.quote.birthday = this.$moment().subtract(v, 'years').format('YYYY-MM-DD')
      } else {
        this.quote.birthday  = null
      }
    },
    g_user () {
      this.quote.name = this.g_user.first_name
      this.quote.email = this.g_user.email
      this.quote.cellphone = this.g_user.cellphone
    },
    birthdate_menu (value) {
      value && this.$nextTick(() => (this.$refs.birthdate_picker.activePicker = 'YEAR'))
    },
    'quote.car_model': {
      handler  (val) {
        if (val !== parseInt(this.$route.query.car_model)) {
          this.quote.car_brand = ''
          this.quote.car_uuid = ''
        }

        this.getCarBrands()
      },
      immediate: true
    },
    'quote.car_brand': {
      handler  (val) {
        if (val !== this.$route.query.car_brand) {
          this.quote.car_uuid = ''
        }

        this.getCarIds()
      },
      immediate: true
    }
  },
  computed: {
    birthdate_formatted: {
      get () {
        if (!this.quote.birthday) return null

        const [year, month, day] = this.quote.birthday.split('-')
        return `${day}/${month}/${year}`
      },
      set () {
        this.quote.birthday = this.quote.birthday
      }
    },
    adult_only () {
      return this.$moment().subtract(18, 'years').format('YYYY-MM-DD')
    }
  },
  methods: {
    // CRUD
    createQuote () {
      this.g_isLoading = true

      this.$api.createCarQuote(
        {...this.quote, campaign_uuid: localStorage.radial},
        response => {
          this.$goTo('/cotizar/auto/' + response.body.data.uuid, 'quoter_car_create_quote')
        },
        response => this.$errorHandling(response).then(() => {
          this.createQuote()
        }, () => {})
      )
    },
    getCarModels () {
      for (var i = 2020; i >= 1996; i--) {
        this.options.car_models.push({ model: i })
      }
    },
    getCarBrands () {
      if (!this.quote.car_model) {
        this.quote.car_brand = ''
        this.quote.car_uuid = ''
        return
      }

      this.g_isLoading = true
      this.$api.getCarBrands(
        this.quote.car_model,
        response => {
          this.options.car_brands = response.data.data
          this.g_isLoading = false

          this.$nextTick(() => {
            if (this.$refs.car_brand) {
              this.$refs.car_brand.focus()
            }
          })
        },
        response => this.$errorHandling(response).then(() => {
          this.getCarBrands()
        }, () => {})
      )
    },
    getCarIds () {
      if (!this.quote.car_brand || !this.quote.car_model) {
        this.quote.car_uuid = ''
        return
      }

      this.g_isLoading = true
      this.$api.getCarVersions(
        this.quote.car_model,
        this.quote.car_brand,
        response => {
          this.options.car_uuids = response.data.data
          this.g_isLoading = false

          this.$nextTick(() => {
            if (this.$refs.car_uuid) {
              this.$refs.car_uuid.focus()
            }
          })
        },
        response => this.$errorHandling(response).then(() => {
          this.getCarIds()
        }, () => {})
      )
    }
  },
  mounted () {
    this.getCarModels()
    if (this.g_auth.access_token) this.$authGetUser()
  }
}
</script>

<style scoped>
  .background {
    background: linear-gradient(180deg, #FCFBFF 70%, #7E5AE8 30%);
  }

  .background-mobile {
    background: linear-gradient(180deg, #FCFBFF 80%, #7E5AE8 20%);
  }

  .elevation {
    box-shadow: -3px -3px 4px 1px rgba(130, 131, 131, 0.08), 5px 5px 6px 1px rgba(130, 131, 131, 0.14);
  }

  .arrow-right {
    z-index: 1;
    margin-top: 20px;
  }

  .rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-top: -20px !important;
    margin-bottom: -10px !important;
    z-index: 1;
  }

  .dialog-bubble {
    max-width: calc(100% - 85px);
  }
</style>

<style>
  .font-12 label {
    font-size: 12px !important;
  }
</style>